import React, { useState } from 'react';
import Modal from 'react-modal';



const VideoModal = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className="video-icon">
      <button
        className="btn-default rounded-player  border bg-white-dropshadow"
        onClick={openModal}
      >
        <span>
          <i className="feather-play"></i>
        </span>
      </button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Video Modal"
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            zIndex: 1000 // Overlay için yüksek z-index değeri
          },
          content: {
            top: '50%',
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '0',
            border: 'none',
            borderRadius: '10px',
            overflow: 'hidden',
            zIndex: 1001 // İçerik için yüksek z-index değeri
          },
        }}
      >
        <video
          controls
          style={{ width: '100%', height: 'auto' }}
          autoPlay
        >
          <source src="/videos/ProdoxAI-Tanitim.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Modal>
    </div>
  );
};

export default VideoModal;
