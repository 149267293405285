import React, { useState, useEffect } from 'react';
import Image from "next/image";
import Link from "next/link";
import sal from "sal.js";
import Info from "../Info/Info"
import boxedLogo from "../../public/images/logo/boxed-logo.png";
import PageHead from "@/pages/Head";
import { useRouter } from 'next/router';
import useList from '@/components/isAuth/isAuth';
import { FormattedMessage, useIntl } from 'react-intl';

export const useAppContext = () => {
  const [toggleAuth, setToggleAuth] = useState(false);
  return { toggleAuth, setToggleAuth };
}

const UserAuth = ({ messages }) => {
  const [showToast, setShowToast] = useState(false);
  const [infoMessage,setInfoMessage] = useState()
  const intl = useIntl();
  const list = useList();
  const { toggleAuth, setToggleAuth } = useAppContext();
  const router = useRouter();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [showReset, setShowReset] = useState(false);

  useEffect(() => {
    sal();
    const cards = document.querySelectorAll(".bg-flashlight");
    cards.forEach((bgflashlight) => {
      bgflashlight.onmousemove = function (e) {
        let x = e.pageX - bgflashlight.offsetLeft;
        let y = e.pageY - bgflashlight.offsetTop;
        bgflashlight.style.setProperty("--x", x + "px");
        bgflashlight.style.setProperty("--y", y + "px");
      };
    });
  }, []);

  const handleSignInSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`/api/signin/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password })
      });

      const data = await response.json();

      if (data.success) {
        localStorage.setItem('mytoken', data.mytoken);
        window.location.href = `/sohbet`;
      } else {
  
        setInfoMessage(data.message)
          setShowToast(true)
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const handleSignUpSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      
      
      setInfoMessage("Şifreler uyuşmuyor !")
      setShowToast(true)
    } else if (email.trim() === '' || password.trim() === '' || name.trim() === '') {
      
      setInfoMessage("Her alanı doldurun !")
      setShowToast(true)
    } else if (name.length < 5) {

      
      setInfoMessage("Kullanıcı adı 5 karakterden kısa olamaz")
      setShowToast(true)
    } else if (/^\d+$/.test(name)) {
    
      setInfoMessage("Kullanıcı adı harf içermeli")
      setShowToast(true)
    } else if (password.length < 5) {
   
      setInfoMessage("Şifre 5 karakterden kısa olamaz")
      setShowToast(true)
    } else if (/^\d+$/.test(password)) {

      setInfoMessage("Şifre harf içermeli")
      setShowToast(true)
    } else if (/^\d+$/.test(phone)) {

      setInfoMessage("Telefonn numarası numara olmalı")
      setShowToast(true)
    } else {
      try {
        const response = await fetch(`/api/signup/`, {
          method: "POST",
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ name, email, password })
        });

        const data = await response.json();

        if (!data.success) {
      
          setInfoMessage(data.msg)
          setShowToast(true)
        } else {
   
          setInfoMessage("Kayıt olma başarılı, giriş yapabilirsiniz")
          setShowToast(true)

         setTimeout(() => {
            window.location.href = `/AuthPage`;
          }, 2000); // 2000ms = 2 saniye
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
  }

  const handleForgotPassword = (e) => {
    e.preventDefault();
    setShowReset(true);
  }

  const handleResetSubmit = async (e) => {
    e.preventDefault();
  
    // Reset işlemi burada başlar, örneğin bir API isteği gönderilir
  }

  return (
    <>
      <PageHead title={`${toggleAuth ? "Log In" : "Sign Up"}`} />
      <div className="signup-area rainbow-section-gapTop-big" data-black-overlay="2">
      <div style={{zIndex:"3"}}>
                  
      <Info 
        message={infoMessage}
        show={showToast}
        onClose={() => setShowToast(false)}
      />
    </div>
        <div className="sign-up-wrapper rainbow-section-gap">
          <div className="sign-up-box bg-flashlight">
            <div className="signup-box-top top-flashlight light-xl">
              <Image src={boxedLogo} width={476} height={158} alt="sign-up logo" />
            </div>
            <div className="separator-animated animated-true"></div>
            <div className="signup-box-bottom">
              <div className="signup-box-content">
                <h4 className="title"><FormattedMessage id="SignIn.title" /></h4>
                {showReset ? (
                  <form onSubmit={handleResetSubmit}>
                    <div className="input-section mail-section">
                      <div className="icon">
                        <i className="feather-mail"></i>
                      </div>
                      <input type="email" placeholder={intl.formatMessage({ id: "SignIn.mail" })} value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <button type="submit" className="btn-default">Gönder</button>
                    <div className="bottom-text">
                      <FormattedMessage id="SignIn.under" />
                      <Link className="btn-read-more ps-2" href="#" onClick={() => { setShowReset(false); setToggleAuth(true); }}>
                        <span><FormattedMessage id="SignUp" /></span>
                      </Link>
                    </div>
                  </form>
                ) : toggleAuth ? (
                  <form onSubmit={handleSignUpSubmit}>
                    <div className="input-section mail-section">
                      <div className="icon">
                        <i className="feather-user"></i>
                      </div>
                      <input type="text" placeholder={intl.formatMessage({ id: "SignUp.name" })} value={name} onChange={(e) => setName(e.target.value)} />
                    </div>
                    <div className="input-section mail-section">
                      <div className="icon">
                        <i className="feather-mail"></i>
                      </div>
                      <input type="email" placeholder={intl.formatMessage({ id: "SignUp.email" })} value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className="input-section password-section">
                      <div className="icon">
                        <i className="feather-lock"></i>
                      </div>
                      <input type="password" placeholder={intl.formatMessage({ id: "SignUp.password" })} value={password} onChange={(e) => setPassword(e.target.value)} />
                    </div>
                    <div className="input-section password-section">
                      <div className="icon">
                        <i className="feather-lock"></i>
                      </div>
                      <input type="password" placeholder={intl.formatMessage({ id: "SignUp.confirm" })} value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                    </div>
                    <button type="submit" className="btn-default">
                      <FormattedMessage id="SignUp" />
                    </button>
                  </form>
                ) : (
                  <form onSubmit={handleSignInSubmit}>
                    <div className="input-section mail-section">
                      <div className="icon">
                        <i className="feather-mail"></i>
                      </div>
                      <input type="email" placeholder={intl.formatMessage({ id: "SignIn.mail" })} value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div id='pass' className="input-section password-section">
                      <div className="icon">
                        <i className="feather-lock"></i>
                      </div>
                      <input type="password" placeholder={intl.formatMessage({ id: "SignIn.password" })} value={password} onChange={(e) => setPassword(e.target.value)} />
                    </div>
                    <div className="forget-text">
                      <button className="btn-read-more" style={{ background: "transparent", border: "none" }} onClick={handleForgotPassword} type="button">
                        <span><FormattedMessage id="SignIn.forgot" /></span>
                      </button>
                    </div>
                    <button id='signin' type="submit" className="btn-default">
                      <FormattedMessage id="SignIn" />
                    </button>
                  </form>
                )}
              </div>
              <div className="signup-box-footer">
                <div className="bottom-text">
                {toggleAuth ?  (<FormattedMessage id="SignUp.under" />):(<FormattedMessage id="SignIn.under" />)}
                {!showReset && (
                    <Link className="btn-read-more ps-2" href="#" onClick={() => setToggleAuth(!toggleAuth)}>
                      {toggleAuth ? <span><FormattedMessage id="SignIn" /></span> : <span><FormattedMessage id="SignUp" /></span>}
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserAuth;
