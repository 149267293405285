import React, { useEffect } from "react";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { FormattedMessage } from 'react-intl';
import DashboardItem from "../../data/header.json";

import menuImg from "../../public/images/menu-img/menu-img-2.png";
import { useAppContext } from "@/context/Context";
import useList from '@/components/isAuth/isAuth';

const Nav = ({messages}) => {

  const router = useRouter();
  const { showItem, setShowItem } = useAppContext();
  const list = useList(); 
  const Admin = list[4]

  const isActive = (href) => router.pathname === href;
  const logout = async (e) => {
    
    try {
      const response = await fetch(`/api/logout/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ "selam" :"selam" })
      });
      const data = await response.json();

      localStorage.removeItem('mytoken')
      window.location.href="/"
     
   
  }catch (error) {
      console.error('Error:', error);
    }
    
  }
  
  return (
    <>
      <ul className="mainmenu">
        
          {/*<a
            href="#"
            onClick={() => setShowItem(!showItem)}
            className={`${!showItem ? "open" : ""}`}
          >
            Dashboard
          </a>
          <div
            className={`rainbow-megamenu right-align with-mega-item-2 ${
              showItem ? "" : "d-block"
            }`}
          >
            <div className="wrapper p-0">
              <div className="row row--0">
                <div className="col-lg-6 single-mega-item">
                  <h3 className="rbt-short-title">DASHBOARD PAGES</h3>
                  <ul className="mega-menu-item">
                    {DashboardItem &&
                      DashboardItem.navDashboardItem.map((data, index) => (
                        <li key={index}>
                          <Link
                            href={data.link}
                            className={isActive(data.link) ? "active" : ""}
                          >
                            {data.text}
                          </Link>
                        </li>
                      ))}
                  </ul>
                </div>
                <div className="col-lg-6 single-mega-item">
                  <div className="header-menu-img">
                    <Image src={menuImg} alt="Menu Split Image" />
                  </div>
                </div>
              </div>
            </div>
          </div>*/}
                    
        {/*<li>
          <Link href="/pricing">Pricing</Link>
                    </li>*/}



                   
        
        {list[1] ? (
          <>
        <li>
          <Link href="/sohbet"><FormattedMessage id="Nav.chat" /></Link>
        </li>
        <li>
          <Link href="/profil"><FormattedMessage id="Nav.profile" /></Link>
        </li>
        {Admin === "admin" && 
          (<li>
            <Link href="/admin">
          
              <FormattedMessage id="Left-nav.admin" />
            </Link>
          </li>)}
        <li >
          <Link style={{display:'none'}} href="/AuthPage"><FormattedMessage id="Nav.signIn" /></Link>
          <Link href="/" onClick={logout} ><FormattedMessage id="Nav.logout" /></Link>
        </li>
        </>
        )
       : (
        <>
          <li>
            <Link href="/AuthPage"><FormattedMessage id="Nav.signIn" /></Link>
            <Link style={{display:'none'}} href="/" onClick={logout} ><FormattedMessage id="Nav.logout" /></Link>
          </li>
          <li style={{display:'none'}}>
            <Link href="/text-generator">Chat</Link>
          </li>
          <li style={{display:'none'}}>
            <Link href="/profil">Profile</Link>
          </li>
          </>
        )}
      </ul>
    </>
  );
};

export default Nav;
