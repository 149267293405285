export const savePinnedChatsToLocalStorage = (pinnedChats) => {
    localStorage.setItem('pinnedChats', JSON.stringify(pinnedChats));
  };
  
  export const loadPinnedChatsFromLocalStorage = () => {
    const savedPinnedChats = localStorage.getItem('pinnedChats');
    return savedPinnedChats ? JSON.parse(savedPinnedChats) : [];
  };
  
  export const saveRenamedChatsToLocalStorage = (renamedChats) => {
    localStorage.setItem('renamedChats', JSON.stringify(renamedChats));
  };
  
  export const loadRenamedChatsFromLocalStorage = () => {
    const renamedChats = localStorage.getItem('renamedChats');
    return renamedChats ? JSON.parse(renamedChats) : [];
  };


  export const removePinnedChatFromLocalStorage = (chatId) => {
    const pinnedChats = loadPinnedChatsFromLocalStorage();
    const updatedPinnedChats = pinnedChats.filter(chat => chat.id !== chatId);
    savePinnedChatsToLocalStorage(updatedPinnedChats);
    loadPinnedChatsFromLocalStorage()
    return updatedPinnedChats;
  };