import { v4 as uuidv4 }   from 'uuid'
import Showdown from 'showdown';

// Eylem Türü Tanımlama


// Eylem Oluşturucu Tanımlama
export const addMessage = (newMessage) => ({
  type:'ADD_MESSAGE',
  payload: {

    content:newMessage.content,
    author:newMessage.author
  }
});


// const converter = new Showdown.Converter();
// let htmlContent = converter.makeHtml();
export const handleChatHistory = (newMessage) => {
 
  const history = []
  const userMessages = newMessage.user_messages || [];
  const assistantMessages = newMessage.assistant_messages || [];
  const maxLength = Math.max(userMessages.length, assistantMessages.length);


  for (let i = 0; i < maxLength; i++) {
    if (i < userMessages.length) {
      console.log(`user ${userMessages[i]}`)
      history.push({
        content: userMessages[i],
        author: "user"
      });
    }
    if (i < assistantMessages.length) {
     
      history.push({
        content: assistantMessages[i],
        author: "ai"
      });
    }
  }

  
  return {
  type:'HANDLE_HISTORY',
  payload: history
}};



// Reducer
export const clearMessages = () => ({
  type: 'CLEAR_MESSAGES'
});




const initialState =[{content:"Merhabalar, size nasıl yardımcı olabilirim ?",author:"ai"}]

export const messagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_MESSAGE':
       
      return   [...state, action.payload];
    case 'CLEAR_MESSAGES':
      return initialState
    case 'HANDLE_HISTORY':
        return [...action.payload]
     
    default:
      return state;
  }
};

