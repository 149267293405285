import { createSlice } from '@reduxjs/toolkit';
import { savePinnedChatsToLocalStorage, loadPinnedChatsFromLocalStorage , saveRenamedChatsToLocalStorage, loadRenamedChatsFromLocalStorage,removePinnedChatFromLocalStorage} from './localStorageHelpers';// İlk state değeri
const initialState = {
  conversationText: null,
  conversationModalVisibliity: 0,
  likeStatus:null,
  chatHistory : [],
  pinnedChats: typeof window !== 'undefined' ? loadPinnedChatsFromLocalStorage() : [],
  renamedChats: typeof window !== 'undefined' ? loadRenamedChatsFromLocalStorage() : [],
};

// feedback slice'ı oluşturma 2121
export const feedbackSlice = createSlice({
  name: 'feedback', // slice'ın adı
  initialState, // ilk state değeri
  reducers: {
    // action creator'ı ve reducer'ı tanımlama
    setConversationText: (state, action) => {
      state.conversationText = action.payload;
    },
    setConversationModalVisibilty: (state, action) => {
      state.conversationModalVisibliity = action.payload;
    },
    setLikeStatus: (state, action) => {
      state.likeStatus = action.payload;
    },
    setChatHistory: (state, action) => {
      state.chatHistory = action.payload;
    },
    setPinnedChats: (state, action) => {
      state.pinnedChats = action.payload;
      savePinnedChatsToLocalStorage(state.pinnedChats);
    },
    deletePinnedChat :(state,action) => {
      const {chat_id} = action.payload;
      removePinnedChatFromLocalStorage(chat_id);
    },
    addRenamedChat: (state, action) => {
      const { session_id, newName } = action.payload;
      const existingIndex = state.renamedChats.findIndex(chat => chat.session_id === session_id);
      if (existingIndex !== -1) {
        // Eğer sohbet mevcutsa, yeni adı ile güncelle
        state.renamedChats[existingIndex].newName = newName;
      } else {
        // Maksimum 25 öğe tutmak için, 25'ten fazla öğe varsa ilk öğeyi sil
        if (state.renamedChats.length >= 25) {
          state.renamedChats.shift();
        }
        state.renamedChats.push({ session_id, newName });
      }
      saveRenamedChatsToLocalStorage(state.renamedChats);
    },
  },
});

// action creator'ı diğer bileşenlerle paylaşma
export const { setConversationText,setConversationModalVisibilty,setLikeStatus ,setChatHistory , setPinnedChats , addRenamedChat ,deletePinnedChat} = feedbackSlice.actions;

// Selector oluşturma
export const selectConversationText = state => state.feedback.conversationText;
export const selectConversationModalVisibility = state => state.feedback.conversationModalVisibliity;
export const selectLikeStatus = state => state.feedback.likeStatus;
export const chatHistory = state => state.feedback.chatHistory;
export const pinnedChats = state => state.feedback.pinnedChats;
export const renamedChats = state => state.feedback.renamedChats;


// Reducer export etme
export default feedbackSlice.reducer;